export default {
  // ? ==========================
  // ? =====  Multilocation  ====
  // ? ==========================

  multiLocation: {},

  // ? ===================
  // ? =====  Layout  ====
  // ? ===================

  navigation: {
    '.container': {
      backgroundColor: 'transparent',
      background: 'linear-gradient(rgba(0, 0, 0, 0.75) 0%, rgba(0, 0, 0, 0.75) 50%, rgba(0, 0, 0, 0) 100%)',
      padding: ['0rem 0.5rem', '0rem 0.5rem', '1rem 1rem', '2rem'],
      zIndex: '1001'
    },

    '.containerScrolled': {
      backgroundColor: 'maroon',
      borderBottom: '2px solid',
      borderColor: 'secondary',
      zIndex: '1001',
      '.hamburger > div': {
        backgroundColor: '#ffffff'
      }
    },
    '.hamburger': {
      position: 'static'
    },

    '.logo': {
      img: {
        opacity: '1',
        maxHeight: ['65px', '75px', '100px', '120px']
      }
    },
    '.logoScrolled': {
      img: {
        opacity: '1',
        maxHeight: ['55px', '70px']
      }
    },
    '.navItemDropdown': {
      alignSelf: 'center'
    },

    '.smallNavMenu > div': {
      border: 'none',
      '.navItem': {
        color: '#d1c270',
        a: {
          fontSize: ['1rem', '1rem', '1rem', '1rem']
        }
      }
    },

    '.navItem': {
      color: 'light',
      margin: '0rem',
      padding: ['0.75rem', '0.75rem', '1rem'],
      width: '100%',
      flexGrow: '1',
      justifyContent: 'center',
      alignItems: 'center',
      display: 'flex',
      ':hover': {
        color: 'inherit'
      }
    },
    '.hamburger > div': {
      backgroundColor: 'white'
    },

    // ? === Nav Menu ===

    '.navMenu, .navMenuOpen': {
      '::before': {
        content: "'Pages'",
        padding: '0.5rem',
        mb: '1.5rem',
        fontWeight: 'bold',
        fontSize: '1.25rem',
        opacity: '0.5',
        borderBottom: '1px solid',
        textTransform: 'uppercase'
      },
      color: 'dark',
      backgroundColor: 'background',
      padding: ['', '', '', '2rem'],
      width: ['100%', '100%', '100%', '100%'],
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      transform: 'unset',
      position: 'fixed',
      left: '0rem',
      right: 'unset',
      '.navItem': {
        textAlign: 'left',
        a: {
          fontFamily: 'heading',
          fontSize: ['1.5rem', '1.75rem', '2.5rem', '3rem'],
          letterSpacing: '0px',
          fontWeight: 'bold',
          color: 'dark',
          transition: 'all ease-in-out 0.7s',
          textDecoration: 'none',
          ':hover': {
            opacity: '0.5'
          }
        }
      },
      '.seperatorLine': {
        display: 'none'
      },
      '.navItemDropdownMenu': {
        position: 'static',
        background: 'transparent',
        textShadow: 'unset'
      }
    },

    '.navMenu': {
      width: ['85%', '60%', '50%', '40%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      transform: 'unset',
      position: 'fixed',
      color: 'white',
      backgroundColor: 'background',
      height: '100vh',
      alignItems: ['flex-start', 'flex-start', 'flex-start', 'flex-start'],
      '.navItem': {
        textAlign: 'left',
        a: {
          fontSize: ['1.5rem', '1.75rem', '2.5rem', '3rem'],
          letterSpacing: '0px',
          fontWeight: 'bold',
          color: 'white',
          transition: 'all ease-in-out 0.7s',
          textDecoration: 'none',
          ':hover': {
            opacity: '0.5'
          }
        }
      }
    },

    // '.navMenuOPen': {},

    '.navBlock': {
      width: ['15%', '40%', '50%', '60%'],
      left: '-100vw',
      top: '0rem',
      right: 'unset',
      height: '100vh',
      position: 'fixed'
    },
    '.navBlockOpen': {
      position: 'fixed',
      backdropFilter: 'blur(1000042px)',
      backgroundImage: 'url(https://img.freepik.com/free-vector/soft-blurred-background_1034-273.jpg?w=2000)',
      backgroundColor: '#000000',
      opacity: 0.98,
      backgroundSize: 'cover',
      filter: 'brightness(0.1)'
    },
    '.phoneSocialContainer': {
      mt: '4rem',
      flexDirection: 'column',
      alignItems: 'flex-start',
      opacity: '0.5',

      '> li': {
        width: 'fit-content',
        fontWeight: 'bold',

        a: {
          fontSize: '20px'
        },
        '.fa-phone': {
          mr: '1rem'
        },
        '.socialLink': {
          svg: {
            width: '26px',
            height: '26px'
          }
        }
      }
    },

    '.navMenuLogo, .smallNavHeading': {
      display: 'none'
    }
  },

  footer: {
    backgroundColor: 'backgroundSecondary',
    '.logo': {
      display: 'none'
    },
    borderTop: '1px solid #515151',
    '.innerHoursContainer': {
      fontSize: '0.75em'
    },
    '.typelabel': {
      fontSize: '0.9rem',
      borderBottom: '1px solid',
      mb: '0.5rem',
      width: 'fit-content'
    },
    '.time': {
      fontSize: '0.85rem'
    }
  },

  ctaWidget: {},

  // ? ====================
  // ? ==== Reuseables ====
  // ? ====================

  title: {
    fontSize: ['1.75rem', '2rem', '2.25rem', '2.5rem', '2.75rem'],
    fontWeight: 'normal',
    letterSpacing: '1px',
    display: 'flex',
    justifyContent: 'flex-start',
    alignItems: 'flex-start',
    textAlign: 'left',
    marginBottom: '1.5rem',
    color: '#d5ccc1',
    order: '1'
  },
  subtitle: {
    color: 'secondary',
    width: 'fit-content',
    textAlign: 'left',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    fontSize: ['1.2rem', '1.4rem', '1.7rem'],
    marginBottom: '1.5rem',
    order: '2',
    border: 'none',
    fontWeight: 'bold',
    fontFamily: 'subheading',
    textTransform: 'uppercase'
  },
  text: {
    textAlign: 'center',
    lineHeight: '1.75',
    fontSize: '1rem',
    order: '3',
    p: {
      lineHeight: '1.75',
      letterSpacing: '0.5px',
      textAlign: 'left',
      fontSize: 'inherit'
    },
    'p:last-child': {
      margin: '0rem'
    }
  },

  borders: {
    '::before': {
      content: "''",
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1699879700/sites/latitude-39/39-icon.png)',
      backgroundSize: '70px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      margin: ['0rem 0rem 1.5rem', '', '0rem 0rem 3rem'],
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '4rem',
      height: '70px',
      width: '100%'
    },

    '::after': {
      content: "''",
      order: '6',
      backgroundImage:
        'url(https://res.cloudinary.com/gonation/image/upload/v1699879700/sites/latitude-39/39-icon.png)',
      backgroundSize: '70px',
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'center center',
      margin: '3rem 0rem 0rem',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      paddingBottom: '4rem',
      height: '70px',
      width: '100%'
    }
  },

  sideBySide1: {
    variant: 'customVariants.borders',
    flexWrap: 'wrap',
    padding: ['2rem 0.5rem 2rem 3rem', '2rem 1rem 2rem 3rem', '3rem', '4rem'],
    '.lazyload-wrapper': {},
    '.content': {},
    '.title': {
      variant: 'customVariants.title',
      color: 'dark'
    },
    '.subtitle': { variant: 'customVariants.subtitle' },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.linksContainer': {
      mt: '2rem',
      justifyContent: 'flex-start',
      '.ctaButton': {
        variant: 'buttons.primary'
      },

      '.secondaryCtaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  centerBlock: {
    '.section': {
      variant: 'customVariants.borders',
      padding: ['2rem 1rem 2rem 3rem', '', '3rem 2rem'],
      backgroundColor: 'rgb(0 0 0 / 63%)',
      margin: '0rem 0rem 0rem auto',
      maxWidth: ['', '', '50%', '33.3%'],
      alignItems: 'flex-start'
    },
    '.title': {
      variant: 'customVariants.title'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle'
    },
    '.text': {
      variant: 'customVariants.text'
    },
    a: {
      order: '4'
    },
    '.linksContainer': {
      mt: '2rem',
      '.ctaButton': {
        variant: 'buttons.primary'
      },

      '.secondaryCtaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  // sideBySide2: {
  //   variant: 'customVariants.sideBySide1',
  //   '.imageContainer': {
  //     height: ['', '', '75vh'],
  //   },
  //   '.content': {
  //     // variant: 'customVariants.borderCorners',
  //     order: '2',
  //     position: 'relative',
  //     alignItems: 'flex-start',
  //     margin: ['1rem 0rem', '', '0rem 1rem'],
  //   },
  //   a: {
  //     order: '4',
  //   },
  // },

  // ? ====================
  // ? ====  Homepage  ====
  // ? ====================

  homepageHero: {
    '.slick-arrow': { display: 'none !important' },
    img: {
      filter: 'brightness(0.5)'
    },
    '.hero_content_container': {
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%,-50%)',
      color: 'white',
      textAlign: 'center',
      width: '100%',
      maxWidth: '1100px'
    },
    '.title': {
      variant: 'customVariants.title',
      color: 'inherit',
      textAlign: 'inherit'
    },
    '.subtitle': {
      variant: 'customVariants.subtitle',
      color: 'inherit',
      textAlign: 'inherit'
    },
    '.text': {
      variant: 'customVariants.text',
      color: 'inherit',
      textAlign: 'inherit'
    },
    '.linksContainer': {
      mt: '2rem',
      '.ctaButton': {
        variant: 'buttons.primary'
      },
      '.secondaryCtaButton': {
        variant: 'buttons.secondary'
      }
    }
  },

  homepageHeroShout: {},

  homepageAbout: {
    variant: 'customVariants.sideBySide1',
    '::after': {
      content: 'none'
    }
  },

  homepageMenu: {
    variant: 'customVariants.centerBlock'
  },

  homepageGallery: {
    variant: 'customVariants.sideBySide1',

    backgroundColor: 'background'
  },
  homepageEvents: {
    variant: 'customVariants.sideBySide1',
    '#a-art-4KDPgBVQTZOEVgee183C-Q': {
      '::before': {
        display: 'none'
      }
    },
    '::before': {
      display: 'none'
    },
    '.image': {
      background: 'black',
      objectFit: 'contain',
      px: 4
    }
  },

  homepageContact: {
    variant: 'customVariants.centerBlock',
    '.section': {
      variant: 'customVariants.borders',
      padding: ['2rem 1rem 2rem 3rem', '', '3rem 2rem'],
      backgroundColor: 'rgb(0 0 0 / 63%)',
      margin: '0rem auto 0rem 0rem',
      maxWidth: ['', '', '50%', '33.3%'],
      alignItems: 'flex-start'
    }
  },

  homepageShout: {
    '.shoutPopUpButton': {
      width: ['', '', '3rem'],
      height: ['', '', '3rem']
    },
    '.text': {
      marginBottom: '0rem'
    },
    '.date': {
      textAlign: 'left'
    },
    '.containerPopUp': {
      border: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? =====  Venue page  =====
  // ? ========================

  venueRestaurant: {
    variant: 'customVariants.sideBySide1'
  },
  venueOutdoor: {
    variant: 'customVariants.sideBySide1'
  },
  venueBar: {
    variant: 'customVariants.sideBySide1'
  },

  // ? ========================
  // ? =====  About page  =====
  // ? ========================

  aboutSection: {
    '.title': {
      borderBottom: '2px solid',
      borderColor: 'primary'
    }
  },

  // ? ========================
  // ? ====  Menu page  ====
  // ? ========================

  menu: {
    '.menuTab': { color: 'white' }
  },

  menuComingSoon: {
    padding: '15vh 2rem',
    '.title': {
      variant: 'customVariants.title',
      order: 'unset'
    },
    '.text': {
      color: 'grey',
      fontSize: '1.25rem',
      '*': {
        color: 'inherit',
        fontSize: 'inherit'
      }
    }
  },

  // ? ========================
  // ? ====  Gallery page  ====
  // ? ========================

  gallery: {
    '.albumTitle': {
      padding: '0.5rem'
    },
    '.sauces ': {
      '.albumImage': {
        objectFit: 'contain',
        gridRowEnd: 'span 2'
      }
    }
  },

  // ? ========================
  // ? ====  Events page  ====
  // ? ========================

  events: {
    '.events-container': {
      maxWidth: 'unset'
    },
    '.no-events-container': {
      padding: '10rem 1rem',
      img: {
        maxHeight: '70px'
        // filter: 'brightness(0) invert(1)',
      }
    },

    '.eventItemImage': {
      minHeight: 'unset',
      width: '25%'
      // height: '100%',
    },
    '.eventDays, .eventDate': {
      color: 'primary'
    },
    '.eventItemDescription': {
      maxHeight: '250px',
      overflowY: 'scroll',
      '::-webkit-scrollbar': {
        display: 'none'
      },
      '-ms-overflow-style': 'none' /* IE and Edge */,
      scrollbarWidth: 'none'
    }
  },

  // ? ========================
  // ? ====  Contact page  ====
  // ? ========================

  contactForm: {
    order: '4',
    h2: {
      variant: 'customVariants.title',
      order: 'unset',
      fontSize: ['1.75rem', '2rem', '2rem', '2.5rem']
    },
    backgroundPosition: 'center center',
    color: 'white',
    backgroundColor: 'background',
    padding: ['3rem 1rem', '', '6rem 1rem'],
    '.inputField, .textField ': {
      fontWeight: '300',
      borderColor: 'white',
      borderWidth: '2px',
      borderRadius: '0px',
      padding: '1rem 0.5rem',
      color: 'white',
      '::placeholder': {
        color: 'white'
      }
    },

    '.submitBtn': {
      variant: 'buttons.secondary',
      height: 'unset',
      width: 'auto'
    }
  },

  locationMap: {
    order: '3',
    '.content_container': {
      padding: '0rem'
    },
    h3: {
      variant: 'customVariants.title',
      justifyContent: 'center',
      display: 'flex',
      order: 'unset',
      width: '100%',
      backgroundColor: 'primary',
      color: 'white',
      padding: '1rem 0.5rem 1rem',
      textAlign: 'center',
      fontSize: ['1.4rem', '1.5rem', '1.75rem', '1.75rem', '2rem'],
      textAlign: 'center',
      alignItems: 'center'
    },
    '.contactDetails-container': {
      padding: '0rem 1rem 3rem'
    },
    '.hours-section': {
      maxWidth: 'unset',
      padding: '0rem',
      '.hoursContainer': {
        padding: ['1rem', '2rem', '3rem 4rem', '3rem 5rem']
      }
    },
    '.dayofWeekText': {
      margin: '0 auto 1rem 0',
      borderBottom: '1px solid'
    },
    '.textContent': {
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      display: 'flex'
    }
  },

  // ? ========================
  // ? ====  Press page  ====
  // ? ========================

  pressMenu: {
    padding: ['1rem', '1rem', '1rem', '1rem'],
    backgroundColor: 'background',
    '.boxesHeading': {
      display: 'none'
    },
    '.textContent': {
      backgroundColor: '#373434'
    },
    '.imageContainer': {
      backgroundColor: '#1d1d1d',
      img: {
        objectFit: 'contain',
        padding: '1rem'
      }
    }
  },

  pressPage: {
    backgroundColor: 'background'
  }
}
