export default {
  primary: {
    backgroundColor: 'black',
    color: '#e7dacf',
    padding: '1rem 2.25rem',
    border: 'solid 2px',
    borderColor: 'primary',
    borderRadius: '20px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: ['0.9rem', '', '1rem'],
    fontWeight: '300',
    fontFamily: 'body',
    transition: 'all ease-in-out 0.5s',
    ':hover': {
      backgroundColor: 'black',
      borderColor: 'black'
    }
  },
  secondary: {
    backgroundColor: 'secondary',
    color: 'light',
    border: 'solid 2px',
    borderColor: 'secondary',
    borderRadius: '0px',
    textTransform: 'uppercase',
    letterSpacing: '1px',
    fontSize: ['0.9rem', '', '1rem'],
    fontFamily: 'body',
    ':hover': {
      backgroundColor: 'primary',
      color: 'white'
    }
  }
}
