export default {
  text: '#6f562a',
  primary: '#e17624',
  secondary: '#9f7425',
  background: '#ffffff',
  maroon: '#1B0202',
  backgroundSecondary: 'black',
  light: '#FFF',
  dark: '#020303'
}
