export default {
  heading: 'Cinzel, serif',
  subheading: 'Futura, sans-serif',
  body: 'Montserrat, sans-serif',
  monospace: 'Menlo, monospace',
  display: 'Philosopher, serif',
  googleFonts: [
    'Poiret One:100,200,300,400,500,600,700,800,900',
    'Montserrat:100,200,300,400,500,600,700,800,900',
    'Italiana',
    'Philosopher',
    'Cinzel'
  ],
  customFamilies: ['Futura'],
  customUrls: ['https://www.gonation.biz/fonts/futura/futura.css']
}
